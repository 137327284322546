//import { getAuthHeaderValue } from "./auth";
import { Auth } from "aws-amplify";
import axios from "axios";

export interface APIResponse<T> {
	data: T | undefined;
	error: string | undefined;
}
interface GenericAsyncFunction<T> {
  (): Promise<T>;
}

export async function genericAsyncAction<T>(action: GenericAsyncFunction<T>): Promise<APIResponse<T>> {
  let data, error;

  try { data = await action(); }
  catch (e) { error = e.message.toString(); }

  return { data, error };
}

export async function fetchUsingGet<T>(address: string, parameters: Record<string, string> | null = null): Promise<APIResponse<T>> {
	return await genericAsyncAction<T>(async () => {
		const currentSession = await Auth.currentSession();
		const accessToken = currentSession.getAccessToken();
		const jwt = accessToken.getJwtToken();

		if (parameters !== null) {
			const queryParams = new URLSearchParams(parameters).toString();
			address = `${address}?${queryParams}`;
		}

		const response = await axios.get(address, {
			headers: {
				"Authorization": `Bearer ${jwt}`
			}
		});

    return response.data;
	});
}

export async function fetchUsingPost<T>(address: string, parameters: BodyInit | null = null): Promise<APIResponse<T>> {
	return await genericAsyncAction<T>(async () => {
		const currentSession = await Auth.currentSession();
		const accessToken = currentSession.getAccessToken();
		const jwt = accessToken.getJwtToken();

    const response = await axios.post(address, {
      parameters,
    },
    {
      headers: {
        "Authorization": `Bearer ${jwt}`
      },
    });

    return response.data;
	});
}

// Converts an object into Record<string, string> for use as GET parameters
// Example:
// 	function test() {
// 		const noConversionNeeded = { hey: "yo" };
// 		const conversionNeeded = { just: 6 };
// 		const x = fetchUsingGet<string>("addy", noConversionNeeded);
// 		const y = fetchUsingGet<string>("addy", toStringRecord(conversionNeeded));
// 	}
export function toStringRecord(parameters: Record<string, string|number|boolean>): Record<string, string> {
	const output = {} as Record<string, string>;

	Object.keys(parameters).forEach((key: string) => {
		output[key] = parameters[key].toString();
	});

	return output;
}
