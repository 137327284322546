import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import { getViewThumbnail, View } from "./api";
import useNotyf from "../../hooks/useNotyf";

export interface ViewThumbProps {
	view: View;
}

function ViewThumb(props: ViewThumbProps): JSX.Element {
	const notyf = useNotyf();
	const [imageData, setImageData] = useState("");

	const workbook = props.view.path.split("/")[0];
	const view = props.view.path.split("/")[1];

	useEffect(() => {
		const getImageData = async () => {
			const { view: { workbookId, id } } = props;
			const { data, error } = await getViewThumbnail(workbookId, id);

			if (data) setImageData(data.data);
			if (error) notyf.error(error);
		};

		getImageData();
	}, [props.view.workbookId, props.view.id]);

	return (
		(imageData === "")
			? (
				<div id="thumbnail-skeleton"></div>
			)
			: (
				<Link to={`workbook/${workbook}/view/${view}`} title={props.view.name}>
					<img src={imageData} alt={props.view.name} />
				</Link>
			)
	);
}

export default ViewThumb;
