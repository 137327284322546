import { APIResponse, fetchUsingGet } from "../../utils/network";

// Contracts
export interface Thumbnail {
	data: string; // base64 encoded image data
}

export interface View {
	id: string;
	workbookId: string;
	path: string;
	name: string;
}

// Methods
export const getViews = async (): Promise<APIResponse<View[]>> =>
  await fetchUsingGet("views/list");

export const getViewThumbnail = async (workbookId: string, viewId: string): Promise<APIResponse<Thumbnail>> =>
  await fetchUsingGet(`views/thumbnail/${workbookId}/${viewId}?${""}`);
