import React, { useEffect, useState } from "react";

import { getViews, View } from "./api";
import useNotyf from "../../hooks/useNotyf";
import ViewThumb from "./ViewThumb";

import "./styles.scss";

function ViewList(): JSX.Element {
	const notyf = useNotyf();
	const [views, setViews] = useState([] as View[]);

	useEffect(() => {
		const loadViews = async () => {
			const { data, error } = await getViews();

			if (data) setViews(data);
			if (error) notyf.error(error);
		};

		loadViews();
	}, [notyf]);

	return (
		<div id="views-list" className={!!!views.length ? "loading" : ""}>
			{
				views.map((view: View) =>
					<ViewThumb key={view.id} view={view} />
				)
			}
		</div>
	);
}

export default ViewList;
