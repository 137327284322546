import React from "react";

import "./styles.scss";

export interface ButtonProps {
	id: string;
	text: string;
	disabled?: boolean;
	type?: "primary" | "secondary";
	onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

function Button(props: ButtonProps): JSX.Element {
	return (
		<div className={"button-wrapper" + ((props.disabled) ? " disabled" : "")}>
			<button
				id={props.id}
				onClick={props.onClick}
				disabled={props.disabled ?? false}
				className={props.type ?? "primary"}
			>
				{props.text}
			</button>
		</div>
	);
}

export default Button;
