import React, { useEffect, useRef, useState } from "react";
import { useConfig } from "@kikoda/delivery-hooks";
import { useParams } from "react-router-dom";

import { useExternalScript } from "../../hooks/useExternalScript";
import useNotyf from "../../hooks/useNotyf";
import { getTableauToken } from "./api";

import "./styles.scss";

interface Viz {
  dispose: () => void;
}

export default function TableauView(): JSX.Element {
  const notyf = useNotyf();
  const [token, setToken] = useState("");
  const { workbook, view } = useParams() as { workbook: string; view: string };
  const { tableauEndpoint } = useConfig();
  const scriptUrl = `${tableauEndpoint}/javascripts/api/tableau-2.8.1.min.js`;
  const tableauScriptState = useExternalScript(scriptUrl);
  const viewPath = `${workbook}/${view}`;
  const viz = useRef(null as Viz | null);

  useEffect(() => {
    const getToken = async () => {
      const { data, error } = await getTableauToken();

      if (data) setToken(data.token);
      if (error) notyf.error(error);
    };

    getToken();
  }, [notyf, viewPath]);

  useEffect(() => {
    if (token !== "" && tableauScriptState === "loaded") {
      const url = `${tableauEndpoint}/trusted/${token}/views/${viewPath}`;
      const containerDiv = document.getElementById("tableau-view");
      const options = {
        hideTabs: true,
        height: "100%",
        width: "100%",
      };
      // Dispose of previous viz before loading new viz.
      viz.current?.dispose();
      viz.current = new window.tableau.Viz(containerDiv, url, options);
      // Clear single use token.
      setToken("");
    }
  }, [tableauEndpoint, tableauScriptState, token, viewPath]);

  return <div id="tableau-view" />;
}
