import { useEffect, useState } from "react";

type LoadingState = "idle" | "loading" | "loaded" | "error";

export function useExternalScript(url: string): string {
  const [state, setState] = useState<LoadingState>(url ? "loading" : "idle");

  useEffect(() => {
    if (!url) {
      setState("idle");
      return;
    }

    const existing = document.querySelector(`script[src="${url}"]`);

    if (existing) {
      setState("loaded");
    } else {
      const script = document.createElement("script");
      script.type = "application/javascript";
      script.src = url;
      script.async = true;
      script.onload = () => setState("loaded");
      script.onerror = () => setState("error");
      document.body.appendChild(script);
    }
  }, [url]);

  return state;
}
