import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from "../Home";
import Menu from "../Menu";
import TableauView from "../TableauView";
import NotFound from "../NotFound";

import "./styles.scss";

function AuthenticatedWrapper(): JSX.Element {
	return (
		<div id="authenticated-wrapper">
			<div id="menu-content">
				<Menu />
			</div>
			<div id="authenticated-content">
				<Switch>
					<Route path="/" exact>
						<Home />
					</Route>
					<Route path="/home" exact>
						<Home />
					</Route>
					<Route path="/workbook/:workbook/view/:view">
						<TableauView />
					</Route>
					<Route path="*">
						<h4 id="public-title">Uh oh! Where is this?</h4>
						<NotFound />
					</Route>
				</Switch>
			</div>
		</div>
	);
}

export default AuthenticatedWrapper;
