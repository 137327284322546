import Amplify from "aws-amplify";
import { WebConfig } from "../typedefs/WebConfig";

export const configureAmplify = (config: WebConfig): void => {
  const { authRegion, authDomain, userPoolId, appClientId, loginURI, logoutURI } = config.additionalConfig.platform.auth;

	Amplify.configure({
		Auth: {
			//REQUIRED - Amazon Cognito Region
			region: authRegion,

			// OPTIONAL - Amazon Cognito User Pool ID
			userPoolId: userPoolId,

			// OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
			userPoolWebClientId: appClientId,

			oauth: {
				domain: `${authDomain}.auth.${authRegion}.amazoncognito.com`,
				scope: ["email", "openid", "aws.cognito.signin.user.admin"],
				redirectSignIn: loginURI,
				redirectSignOut: logoutURI,
				responseType: "code",
			}
		}
	});

	return;
};
