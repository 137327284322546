import { APIResponse, fetchUsingGet } from "../../utils/network";

export interface MenuItem {
	text: string;
	workBookId: string;
	viewId: string;
  path?: string;
	subMenuItems: MenuItem[];
}

export const getMenuItems = async (): Promise<APIResponse<MenuItem[]>> =>
  await fetchUsingGet("views/workbooks");
