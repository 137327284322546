import React, { useEffect, useState } from "react";
import { AmplifyAuthenticator } from "@aws-amplify/ui-react";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { CognitoUser } from "@aws-amplify/auth";

import AuthenticatedWrapper from "./AuthenticatedWrapper";
import PublicWrapper from "./PublicWrapper";

import "../sass/normalize.scss";
import "../sass/layout.scss";
import "../sass/loader.scss";
import "notyf/notyf.min.css";

function App(): JSX.Element {
	const [authState, setAuthState] = useState<AuthState>();
	const [user, setUser] = useState<CognitoUser | undefined>();

	useEffect(() => {
		return onAuthUIStateChange((nextAuthState, authData) => {
			setAuthState(nextAuthState);
			setUser(authData as CognitoUser);
		});
	}, []);

	return (
		(authState === AuthState.SignedIn && user)
			? (
				<AuthenticatedWrapper />
			)
			: (
				<AmplifyAuthenticator>
					<PublicWrapper />
				</AmplifyAuthenticator>
			)
	);
}

export default App;
