import React, { useEffect, useState } from "react";
import { Link, useLocation, useRouteMatch } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";

import { getMenuItems, MenuItem } from "./api";
import useNotyf from "../../hooks/useNotyf";

import FullLogo from "../../images/analytics-logo-white.png";
import LogoIcon from "../../images/analytics-icon-collapsed.png";
import LeftArrow from "../../images/left-arrow.svg";

import "./styles.scss";
import useLocalStorage from "../../hooks/useLocalStorage";

function Menu(): JSX.Element {
	const notyf = useNotyf();
	const { pathname } = useLocation() as { pathname: string };
	const { params: { workbook, view } } = useRouteMatch('/workbook/:workbook/view/:view') as { params: { workbook: string, view: string }} || { params: {} };

	const [collapsed, toggleCollapsed] = useLocalStorage('menuOpen', false);
	const [activeWorkbook, setActiveWorkbook] = useState(workbook);
	const [menuItems, setMenuItems] = useState([] as MenuItem[]);

	useEffect(() => {
		const loadMenuItems = async () => {
			const { data, error } = await getMenuItems();

			if (data) setMenuItems(data);
			if (error) notyf.error(error);
		};

		loadMenuItems();
	}, [notyf, getMenuItems]);

	useEffect(() => {
		setActiveWorkbook(workbook);
	}, [pathname]);

	const onMenuItemClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
		event.preventDefault();
		const targetElement = event.target as Element;
		setActiveWorkbook(targetElement.id);
	};

	const handleLogout: React.MouseEventHandler<HTMLButtonElement> = async (event) => {
		if (event) event.preventDefault();

		try {
			await Auth.signOut();
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<nav id="navigation" className={collapsed ? "collapsed" : ""}>
			<div id="menu-header">
				<img src={LeftArrow} onClick={() => toggleCollapsed(!collapsed)} alt="Collapse Menu" />
				<img src={collapsed ? LogoIcon : FullLogo} alt="Menu Logo" />
			</div>

			<div id="menu-body" className={!!!menuItems.length ? "loading" : ""}>
				{
					!!menuItems.length &&
						<ul>
							<li className={pathname === "/home" ? "active" : ""}>
								<Link to="/home" onClick={() => setActiveWorkbook("")}>Home</Link>
							</li>

							{
								menuItems.map(item => {
									const workbookName = item.text.replaceAll(" ", "");

									return (
										<li key={item.workBookId}
											className={(activeWorkbook === workbookName) ? "active" : ""}>
											<button onClick={onMenuItemClick}
												id={workbookName}
											>{item.text}</button>

											<ul>
												{
													!!item.subMenuItems.length &&
														item.subMenuItems.map(subitem => {
                              const CUrlWorkbook = subitem.path?.split("/")[0];
                              const CUrlView = subitem.path?.split("/")[1];

															return (
																<li key={subitem.workBookId + subitem.viewId}
																	className={view === CUrlView ? "active" : ""}>
																	<Link to={`/workbook/${CUrlWorkbook}/view/${CUrlView}`} title={subitem.text}>{subitem.text}</Link>
																</li>
															);
														})
												}
											</ul>
										</li>
									);
								})
							}
						</ul>
				}

				<div onClick={() => toggleCollapsed(!collapsed)}>
					&nbsp;
				</div>
			</div>

			<div id="menu-foot">
				<a href="https://www.kikoda.com/analytics" target="_blank" rel="noopener noreferrer">Visit Us</a>
				<button onClick={event => handleLogout(event)}>Logout</button>
			</div>
		</nav>
	);
}

export default Menu;
