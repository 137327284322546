import React from "react";

import LinkBox from "../LinkBox";
import ViewList from "../ViewList";

import "./styles.scss";

function Home(): JSX.Element {
	return (
		<div id="home">
			<h1>Welcome to the Kikoda Analytics Portal</h1>
			<p>This interactive portal is designed to give you a fully immersive analytics dashboard experience. Choose your path below to see how streamlining your data can give you the right information at the right time, unlock never before seen insights, and improve your organization from the top-down. We make your data work for you – unlock limitless potential through Kikoda-powered data science.</p>
			<ViewList />
			<div className="link-box-header">
				<span className="header-text">Better Analytics. Better Insights. Better Outcomes.</span>
			</div>

			<div className="link-box-wrapper">
				<LinkBox
					variant="download"
					buttonText="Download PDF"
					title="kikoda.com/analytics"
					to="assets/0192_Analytics_One_Pager_21_June.pdf"
					body="Download our PDF to learn more about our data analytics services."
				/>

				<LinkBox
					disabled
					variant="link"
					buttonText="Discover Kikoda Analytics (coming soon)"
					title="analytics.kikoda.com"
					to="https://analytics.kikoda.com"
					body="Guided video demos, hands-on interactive dashboards, and more!"
				/>
			</div>
		</div>
	);
}

export default Home;
