import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { loadConfig, asyncWithConfigProvider } from "@kikoda/delivery-hooks";

import App from "./components/App";
import reportWebVitals from "./reportWebVitals";
import { configureAmplify } from "./utils/configureAmplify";
import { WebConfig } from "./typedefs/WebConfig";
import axios from "axios";

const render = async (host = '') => {
  const config = await loadConfig<WebConfig>(host);
  const ConfigProvider = await asyncWithConfigProvider<WebConfig>({ host, config });

  axios.defaults.baseURL = config.additionalConfig.platform.backend.endpoint;

  configureAmplify(config);

  ReactDOM.render(
    <React.StrictMode>
      <BrowserRouter>
        <ConfigProvider>
          <App />
        </ConfigProvider>
      </BrowserRouter>
    </React.StrictMode>,
    document.getElementById("root")
  );
};

// Async blocking render
render();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
