import React from "react";
import { useConfig } from "@kikoda/delivery-hooks";
import { WebConfig } from "../../typedefs/WebConfig";
import Button from "../Button";

import "./styles.scss";

function Login(): JSX.Element {
  const config: WebConfig = useConfig();

	const handleLoginClick: React.MouseEventHandler<HTMLButtonElement> = (event) => {
		if (event) event.preventDefault();

		// redirect to Cognito AUTHORIZATION ENDPOINT
		// https://docs.aws.amazon.com/cognito/latest/developerguide/authorization-endpoint.html
		// not using amplify.Auth.federatedSignIn() here because it does not correctly set idp_identifier
    const { authRegion, authDomain, idpIdentifier, appClientId, loginURI } = config.additionalConfig.platform.auth;

		window.location.replace(
			"https://" +
			`${authDomain}.auth.` +
			`${authRegion}.amazoncognito.com` +
			"/oauth2/authorize?" +
			`idp_identifier=${idpIdentifier}` +
			"&response_type=code" +
			`&client_id=${appClientId}` +
			`&redirect_uri=${loginURI}`
		);
	};

	return (
		<div id="login-form" >
			<Button
				id="submit-button"
				onClick={event => handleLoginClick(event)}
				text="Login with SSO"
			/>
		</div>
	);
}

export default Login;
