import React from "react";
import { Guid } from "guid-typescript";

import Button from "../Button";

import "./styles.scss";

export interface LinkBoxProps {
	disabled?: boolean;
	variant: "link" | "download";
	title: string;
	body?: string;
	buttonText: string;
	to: string;
}

function LinkBox(props: LinkBoxProps): JSX.Element {
	const Link = () => (
		<Button
			id={Guid.create().toString()}
			disabled={props.disabled ?? false}
			text={props.buttonText}
			type="secondary"
		/>
	);

	return (
		<div className="link-box">
			<h4>{props.title}</h4>
			{
				props.body &&
				<p>{props.body}</p>
			}
			{props.variant === "download" &&
				<a href={props.to} download>
					<Link />
				</a>
			}
			{props.variant === "link" &&
				<a href={props.to} target="_blank" rel="noopener noreferrer">
					<Link />
				</a>
			}
		</div>
	);
}

export default LinkBox;
