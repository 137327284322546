import React from "react";
import { Route, Switch } from "react-router-dom";

import Login from "../Login";
import NotFound from "../NotFound";
import useQueryString from "../../hooks/useQueryString";

import logo from "../../images/analytics-logo.png";

import "./styles.scss";

function PublicWrapper(): JSX.Element {
	const queryString = useQueryString();
	const isLoading = !!queryString.get("code");

	return (
		<div id="public-wrapper" slot="sign-in">
			<div id="public-content" className={isLoading ? "loading" : ""} slot="loading">
				<Switch>
					<Route path="/" exact>
						<h4 id="public-title">Login to your account</h4>
						<Login />
					</Route>
					<Route path="/home" exact>
						<h4 id="public-title">Login to your account</h4>
						<Login />
					</Route>
					<Route path="*">
						<h4 id="public-title">Uh oh! Where is this?</h4>
						<NotFound />
					</Route>
				</Switch>
			</div>

			<div id="public-image">
				<img id="logo" src={logo} alt="Kikoda Analytics Logo" />
			</div>
		</div>
	);
}

export default PublicWrapper;
